:root {
    --fc-small-font-size: 0.85em;
    --fc-page-bg-color: #ffffff;
    --fc-neutral-bg-color: rgb(208 208 208 / 30%);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #{$color-brown-30};
    --fc-button-text-color: #ffffff;
    --fc-button-bg-color: #{$color-blue};
    --fc-button-border-color: #{$color-blue};
    --fc-button-hover-bg-color: #{$color-blue-hover};
    --fc-button-hover-border-color: #{$color-blue-hover};
    --fc-button-active-bg-color: #{$color-blue-hover};
    --fc-button-active-border-color: #{$color-blue-hover};
    --fc-event-bg-color: #{$color-blue};
    --fc-event-border-color: #ffffff;
    --fc-event-text-color: #ffffff;
    --fc-event-selected-overlay-color: rgb(0 0 0 / 25%);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: rgb(215 215 215 / 30%);
    --fc-bg-event-color: rgb(143 223 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgb(188 232 241 / 30%);
    --fc-today-bg-color: rgb(255 220 40 / 15%);
    --fc-now-indicator-color: #ff0000;
}

.calendar-page {
    display: flex;
    height: calc(100% - 132px); // 132px is the height of the header (73px) + footer (59px)
}

.room-selection {
    height: 100%;
    width: 300px;
    overflow-y: auto;

    ul,
    .form-check-label {
        padding-left: 20px;
        padding-top: 5px;
    }

    ul {
        margin-bottom: 2px;
    }

    .form-check-label {
        display: flex;
    }

    li {
        margin: 0;
        padding: 2px 0;

        &::before {
            content: none;
        }
    }

    .btn {
        margin-bottom: 10px;
    }

    .form-check {
        padding-left: 0;

        input {
            display: none;
        }
    }

    .cleanup-button {
        margin-bottom: 0;
    }

    .room-item {
        border-radius: $border-radius;

        .form-check {
            border-radius: var(--bs-card-border-radius);
        }

        &.is-visible .form-check {
            background-color: rgb(0 255 0 / 10%);
        }

        &:not(.is-visible) .form-check {
            background-color: rgb(255 0 0 / 10%);
        }
    }
}

.collapse-horizontal,
.collapsing.collapse-horizontal {
    height: 100%;
}

.fc-toolbar {
    flex-flow: row wrap;

    .fc-toolbar-chunk {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        margin: 0 -0.75rem;

        &:last-child {
            justify-content: end;
        }
    }

    .fc-button-group,
    .fc-today-button {
        margin: 0 0.75rem;
    }

    .fc-toolbar-title {
        margin: 0 32px;
    }

    .fc-button {
        margin-bottom: 12px;

        @media print {
            display: none;
        }
    }

    // stylelint-disable-next-line selector-max-compound-selectors -- overwriting library styles
    .fc-direction-ltr & > * > :not(:first-child) {
        margin: 0 0.75rem;
    }
}

.bulk-selection {
    @media print {
        display: none;
    }

    display: flex;
    flex-direction: column;

    button {
        margin-left: 5px;
    }
}

.fc .fc-daygrid-event {
    margin-top: unset;
}

.fc-event-main {
    overflow: hidden;
}

.fc-event-main > i {
    font-style: normal;
}

.svg-inline--fa.fa-w-10 {
    width: 0.875em;
}

.svg-inline--fa.fa-w-18 {
    width: 1.25em;
}

.toggle-room-selection {
    height: 100%;
}

.room-name {
    padding-left: 5px;
}

.expand-controller {
    padding-right: 5px;
}

.refresh-agenda {
    &.is-loader {
        animation: spin 2s linear infinite;

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.event-content-room {
    background: #ffffff52;
    padding: 3px 5px 0;
    border-radius: 50px;
    display: inline-block;
    margin-right: 5px;

    &.is-first-physical {
        background: #333333;
        color: #ffffff;
    }
}

.event-content-divider {
    margin-right: 5px;
}

.first-physical-indicator {
    background: #333333;
    color: #ffffff;
    display: inline-block;
    padding: 0 3px;
}

.coordinator-color-indicator {
    display: inline-block;
    color: #ffffff;
    margin: 0 4px;
}

.event-wrapper {
    padding: 5px;
}
