.email-body-input,
.email-subject-input {
    margin-bottom: 15px;

    /* stylelint-disable-next-line selector-class-pattern -- vendor draft.js */
    .DraftEditor-editorContainer {
        margin-top: 5px;
        margin-bottom: 15px;
        border: 1px solid #ced4da;
        padding: 10px 12px;
        border-radius: 4px;
        overflow: auto;
    }
}

.email-body-input {
    /* stylelint-disable-next-line selector-class-pattern -- vendor draft.js */
    .DraftEditor-editorContainer {
        height: 200px;
    }
}

.email-subject-input {
    /* stylelint-disable-next-line selector-class-pattern -- vendor draft.js */
    .DraftEditor-editorContainer {
        height: 100px;
    }
}
