// @import '~react-toastify/scss/main';
@use '~ag-grid-community/styles' as ag;

@import '~react-toastify/dist/ReactToastify.css';
@import '~draft-js/dist/Draft.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import 'var-overrides';
@import '~bootstrap/scss/bootstrap';
@import 'class-overrides';
@import 'custom-variables';
@import 'evaluations';
@import 'fonts';
@import 'typography';
@import 'generic';
@import 'grids';
@import 'header';
@import 'calendar';
@import 'footer';
@import 'status-change-modal';
@import 'training-description-program';
@import 'email-templates-editor';
@import 'organizations-page';
@import 'modals';
@import 'errors';

@include ag.grid-styles(
    (
        theme: alpine,
    )
);
