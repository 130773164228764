// .page {
//     width: $width;
// }

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
}

.h-vh {
    height: 100vh;
}

.template-list {
    h4 {
        font-size: 14pt;
        margin-bottom: 0;
    }

    p {
        font-size: 11pt;
        margin-bottom: 0;
    }
}
