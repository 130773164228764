@use 'sass:color';

.status-change-modal {
    max-width: 1500px;
}

.mass-status-change-modal {
    max-width: calc(100vw - 200px);

    .list-group {
        overflow-y: scroll;
        height: 256px;
    }

    .mass-status-change-modal-content {
        max-height: calc(100vh - 50px);

        .modal-body {
            flex-direction: column;

            .list-group {
                overflow-y: scroll;
                max-height: calc(100% - 160px);
            }
        }
    }
}

.status-change-modal,
.mass-status-change-modal,
.create-contract-modal,
.create-evaluation-modal,
.templates-page {
    .list-group-item:hover {
        background-color: color.adjust($color-brown-10, $lightness: 3%);
        cursor: pointer;
    }

    .template-preview {
        white-space: pre-wrap;
    }

    .list-group-item.active-template {
        background-color: color.adjust($secondary, $lightness: 50%);
        box-shadow: inset -8px 0 0 -5px $secondary;
    }
}
