.general-grid {
    height: calc(100% - 208px); // 208px is the height of the header (149px) + footer (59px)
}

.mass-status-update-grid {
    height: 192px;
    margin-top: 12px;
}

.grid-quick-search-label {
    width: calc(100% - 60px);
}

.ag-theme-alpine .ag-root-wrapper {
    border-width: 0;
}

.ag-overlay-wrapper .ag-react-container {
    transform: translate(-50px, -50px);

    // stylelint-disable-next-line
    & > span > span {
        border-radius: unset;
    }
}

.general-grid .inscription-row-highlight,
.mass-status-update-grid .inscription-row-highlight {
    background-color: #ffc9c9;
}

.predefined-filters .form-check.is-active {
    background-color: rgb(0 255 0 / 10%);
}

.edit-column .edit-button-style {
    position: absolute;
    top: 4px;
}
